import "../App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import Footer from "../Footer/Footer";
import NavbarH from "../Navbar/Navbar";
import Support from "../Support/Support";
import TermOfService from "../TermOfService/TermOfService";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import HomeRoutes from "./Components/HomeRoutes";

function App() {
  return (
    <div
    style={{
      overflow: 'hidden'
    }}
    >
      <div
        style={{
          position: "fixed",
          top: 0,
          zIndex:9999,
          width: "100%",
        }}
      >
        <NavbarH />
      </div>
      <Routes>
        <Route exact path="/" element={<HomeRoutes />} />
        <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route exact path="/support" element={<Support />} />
        <Route exact path="/termofservice" element={<TermOfService />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
