import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs col-sm-12 col-md-12 col-lg-12 p-0">
            <header className="genric-p">
              <div className="gen-ban-cont">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-xs col-sm-12 col-md-6 col-lg-6 offset-md-3 offset-lg-3 text-center">
                      <h1 className="text-white">Privacy Policy</h1>
                      {/* <p class="text-white">Have questions about our products, features, trials, or pricing? Need a demo? Our teams will help you.</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <section className="contact-us">
              <div className="container address-detail">
                <div className="row">
                  <h2>Introduction </h2>
                  <p>
                    RE-SURE COLORADO INC (“Company” or “We”) respect your
                    privacy and are committed to protecting it through our
                    compliance with this policy.{" "}
                  </p>
                  <p className="mt-4">
                    This policy describes the types of information we may
                    collect from you or that you may provide when you visit the
                    website www.resure-re.com (our “Website”) and our practices
                    for collecting, using, maintaining, protecting, and
                    disclosing that information.
                  </p>
                  <p className="mt-4">
                    This policy applies to information we collect:
                  </p>
                  <div className="listalign mt-4">
                    <ul>
                      <li>On this Website.</li>
                      <li>
                        In email, text, and other electronic messages between
                        you and this Website.
                      </li>
                      <li>
                        Through mobile and desktop applications you download
                        from this Website, which provide dedicated
                        non-browser-based interaction between you and this
                        Website.
                      </li>
                    </ul>
                  </div>
                  <p className="mt-4">
                    It does not apply to information collected by:{" "}
                  </p>
                  <p></p>
                  <div className="listalign mt-4">
                    <ul>
                      <li>
                        Us [SP1] offline or through any other means, including
                        on any other website operated by Company or any third
                        party (including our affiliates and subsidiaries); or{" "}
                      </li>
                      <li>
                        Any third party [SP2] (including our affiliates and
                        subsidiaries), including through any application or
                        content (including advertising) that may link to or be
                        accessible from or on the Website.{" "}
                      </li>
                      <ul>
                        <div>
                          <p className="mt-4">
                            Please read this policy carefully to understand our
                            policies and practices regarding your information
                            and how we will treat it. If you do not agree with
                            our policies and practices, your choice is not to
                            use our Website. By accessing or using this Website,
                            you agree to this privacy policy. This policy may
                            change from time to time (see Changes to Our Privacy
                            Policy). Your continued use of this Website after we
                            make changes is deemed to be acceptance of those
                            changes, so please check the policy periodically for
                            updates.{" "}
                          </p>
                        </div>
                      </ul>
                    </ul>
                  </div>
                  <h3 className="mt-4"> Children Under the Age of 16 </h3>
                  <p className="mt-4">
                    Our Website is not intended for children under 16 years of
                    age. No one under age 16 may provide any information to or
                    on the Website. We do not knowingly collect personal
                    information from children under 16. If you are under 16, do
                    not use or provide any information on this Website or on or
                    through any of its features. If we learn we have collected
                    or received personal information from a child under 16
                    without verification of parental consent, we will delete
                    that information. If you believe we might have any
                    information from or about a child under 16, please contact
                    us at:{" "}
                  </p>
                  <dl>
                    <dt>8480 E. Orchard Road</dt>
                    <dt>Suite 3000</dt>
                    <dt>Greenwood Village, CO 80111</dt>
                    <dt>Support@hana-software.com</dt>
                    <dt>888-217-7508</dt>
                  </dl>
                  <p className="mt-4">
                    California residents under 16 years of age may have
                    additional rights regarding the collection and sale of their
                    personal information. Please see Your California Privacy
                    Rights for more information.{" "}
                  </p>
                  <h3 className="mt-4">
                    Information We Collect About You and How We Collect It{" "}
                  </h3>
                  <p className="mt-4">
                    We collect several types of information from and about users
                    of our Website, including information:{" "}
                  </p>
                  <div className="listalign mt-4">
                    <ul>
                      <li>
                        {" "}
                        By which you may be personally identified, such as name,
                        postal address, e-mail address, telephone number, or any
                        other identifier by which you may be contacted online or
                        offline (“personal information”);{" "}
                      </li>
                      <li>
                        {" "}
                        That is about you but individually does not identify
                        you; and/or
                      </li>
                      <li>
                        About your internet connection, the equipment you use to
                        access our Website, and usage details.
                      </li>
                    </ul>
                  </div>
                  <p className="mt-4">We collect this information: </p>
                  <div className="listalign mt-4">
                    <ul>
                      <li> Directly from you when you provide it to us.</li>
                      <li>
                        {" "}
                        Automatically as you navigate through the site.
                        Information collected automatically may include usage
                        details, IP addresses, and information collected through
                        cookies, web beacons, and other tracking technologies.
                      </li>
                      · From third parties, for example, our business partners.
                    </ul>
                  </div>
                  <h3 className="mt-4"> Information You Provide to Us. </h3>
                  <p className="mt-4">
                    The information we collect on or through our Website may
                    include:{" "}
                  </p>
                  <div className="listalign mt-4">
                    <ul>
                      <li>
                        {" "}
                        Information that you provide by filling in forms on our
                        Website. This includes information provided at the time
                        of registering to use our Website, subscribing to our
                        service, posting material, or requesting further
                        services. We may also ask you for information when you
                        report a problem with our Website.
                      </li>
                      <li>
                        {" "}
                        Records and copies of your correspondence (including
                        email addresses), if you contact us.
                      </li>
                      <li>
                        Details of transactions you carry out through our
                        Website and of the fulfillment of your orders. You may
                        be required to provide financial information before
                        placing an order through our Website.
                      </li>
                    </ul>
                  </div>
                  <p className="mt-4">
                    You also may provide information to be published or
                    displayed (hereinafter, “posted”) on public areas of the
                    Website, or transmitted to other users of the Website or
                    third parties (collectively, “User Contributions”). Your
                    User Contributions are posted on and transmitted to others
                    at your own risk. Although we limit access to certain pages,
                    please be aware that no security measures are perfect or
                    impenetrable. Additionally, we cannot control the actions of
                    other users of the Website with whom you may choose to share
                    your User Contributions. Therefore, we cannot and do not
                    guarantee that your User Contributions will not be viewed by
                    unauthorized persons.{" "}
                  </p>
                  <h3 className="mt-4">
                    {" "}
                    Information We Collect Through Automatic Data Collection
                    Technologies.{" "}
                  </h3>
                  <p className="mt-4">
                    As you navigate through and interact with our Website, we
                    may use automatic data collection technologies to collect
                    certain information about your equipment, browsing actions,
                    and patterns, including:{" "}
                  </p>
                  <div className="listalign mt-4">
                    <ul>
                      <li>
                        Details of your visits to our Website, including traffic
                        data and other communication data and the resources that
                        you access and use on the Website.
                      </li>
                      <li>
                        Information about your computer and internet connection,
                        including your IP address, operating system, and browser
                        type.
                      </li>
                    </ul>
                  </div>
                  <p className="mt-4">
                    The information we collect automatically may include
                    personal information. It helps us to improve our Website and
                    to deliver a better and more personalized service, including
                    by enabling us to:{" "}
                  </p>
                  <div className="listalign mt-4">
                    <ul>
                      <li>Estimate our audience size and usage patterns.</li>
                      <li>
                        Store information about your preferences, allowing us to
                        customize our Website according to your individual
                        interests.
                      </li>
                      <li>Speed up your searches.</li>
                      <li>Recognize you when you return to our Website.</li>
                    </ul>
                    <div>
                      <p className="mt-4">
                        The technologies we use for this automatic data
                        collection may include:{" "}
                      </p>
                      <div className="listalign mt-4">
                        <ul>
                          <li>
                            Cookies (or browser cookies). A cookie is a small
                            file placed on the hard drive of your computer. You
                            may refuse to accept browser cookies by activating
                            the appropriate setting on your browser. However, if
                            you select this setting you may be unable to access
                            certain parts of our Website. Unless you have
                            adjusted your browser setting so that it will refuse
                            cookies, our system will issue cookies when you
                            direct your browser to our Website.
                          </li>
                          <li>
                            Flash Cookies. Certain features of our Website may
                            use local stored objects (or Flash cookies) to
                            collect and store information about your preferences
                            and navigation to, from, and on our Website. Flash
                            cookies are not managed by the same browser settings
                            as are used for browser cookies. For information
                            about managing your privacy and security settings
                            for Flash cookies, see Choices About How We Use and
                            Disclose Your Information.
                          </li>
                          <li>
                            Web Beacons. Pages of our Website may contain small
                            electronic files known as web beacons (also referred
                            to as clear gifs, pixel tags, and single-pixel gifs)
                            that permit the Company, for example, to count users
                            who have visited those pages and for other related
                            website statistics (for example, recording the
                            popularity of certain website content and verifying
                            system and server integrity).
                          </li>
                        </ul>
                      </div>
                      <h3 className="mt-4">
                        Third-Party Use of Cookies and Other Tracking
                        Technologies{" "}
                      </h3>
                      <p className="mt-4">
                        Some content or applications, including advertisements,
                        on the Website are served by third-parties, including
                        advertisers, ad networks and servers, content providers,
                        and application providers. These third parties may use
                        cookies alone or in conjunction with web beacons or
                        other tracking technologies to collect information about
                        you when you use our website. The information they
                        collect may be associated with your personal information
                        or they may collect information, including personal
                        information, about your online activities over time and
                        across different websites and other online services.
                        They may use this information to provide you with
                        interest-based (behavioral) advertising or other
                        targeted content.{" "}
                      </p>
                      <p className="mt-4">
                        We do not control these third parties’ tracking
                        technologies or how they may be used. If you have any
                        questions about an advertisement or other targeted
                        content, you should contact the responsible provider
                        directly. For information about how you can opt out of
                        receiving targeted advertising from many providers, see
                        Choices About How We Use and Disclose Your Information.{" "}
                      </p>
                      <h3 className="mt-4">How We Use Your Information </h3>
                      <p className="mt-4">
                        We use information that we collect about you or that you
                        provide to us, including any personal information:{" "}
                      </p>
                      <div className="listalign mt-4">
                        <ul>
                          <li>
                            To present our Website and its contents to you.
                          </li>
                          <li>
                            To provide you with information, products, or
                            services that you request from us.
                          </li>
                          <li>
                            To fulfill any other purpose for which you provide
                            it.
                          </li>
                          <li>
                            To provide you with notices about your account,
                            including expiration and renewal notices.
                          </li>
                          <li>
                            To carry out our obligations and enforce our rights
                            arising from any contracts entered into between you
                            and us, including for billing and collection.
                          </li>
                          <li>
                            To notify you about changes to our Website or any
                            products or services we offer or provide though it.
                          </li>
                          <li>
                            To allow you to participate in interactive features
                            on our Website.
                          </li>
                          <li>
                            In any other way we may describe when you provide
                            the information.
                          </li>
                          <li>For any other purpose with your consent.</li>
                        </ul>
                      </div>
                      <p className="mt-4">
                        We may also use your information to contact you about
                        our own and third-parties’ goods and services that may
                        be of interest to you. If you do not want us to use your
                        information in this way, please adjust your user
                        preferences in your account profile. For more
                        information, see Choices About How We Use and Disclose
                        Your Information.{" "}
                      </p>
                      <h3 className="mt-4">Disclosure of Your Information </h3>
                      <p className="mt-4">
                        We may disclose aggregated information about our users,
                        and information that does not identify any individual,
                        without restriction.{" "}
                      </p>
                      <p className="mt-4">
                        We may disclose personal information that we collect or
                        you provide as described in this privacy policy:
                      </p>
                      <div className="listalign mt-4">
                        <ul>
                          <li>To our subsidiaries and affiliates.</li>
                          <li>
                            To contractors, service providers, and other third
                            parties we use to support our business.
                          </li>
                          <li>
                            To a buyer or other successor in the event of a
                            merger, divestiture, restructuring, reorganization,
                            dissolution, or other sale or transfer of some or
                            all of RE-SURE COLORADO INC’s assets, whether as a
                            going concern or as part of bankruptcy, liquidation,
                            or similar proceeding, in which personal information
                            held by RE-SURE COLORADO INC about our Website users
                            is among the assets transferred.
                          </li>
                          <li>
                            To fulfill the purpose for which you provide it.
                          </li>
                          <li>
                            For any other purpose disclosed by us when you
                            provide the information.
                          </li>
                          <li>With your consent.</li>
                        </ul>
                      </div>
                      <p className="mt-4">
                        We may also disclose your personal information:{" "}
                      </p>
                      <div className="listalign mt-4">
                        <ul>
                          <li>
                            To comply with any court order, law, or legal
                            process, including to respond to any government or
                            regulatory request.{" "}
                          </li>
                          <li>
                            To enforce or apply our terms of use or {"{"}Invalid
                            hyperlink URL{"}"} and other agreements, including
                            for billing and collection purposes.{" "}
                          </li>
                          <li>
                            If we believe disclosure is necessary or appropriate
                            to protect the rights, property, or safety of
                            RE-SURE COLORADO INC, our customers, or others.{" "}
                          </li>
                        </ul>
                      </div>
                      <h3 className="mt-4">
                        Choices About How We Use and Disclose Your Information{" "}
                      </h3>
                      <p className="mt-4">
                        We strive to provide you with choices regarding the
                        personal information you provide to us. We have created
                        mechanisms to provide you with the following control
                        over your information:
                      </p>
                      <div className="listalign mt-4">
                        <ul>
                          <li>
                            Tracking Technologies and Advertising. You can set
                            your browser to refuse all or some browser cookies,
                            or to alert you when cookies are being sent. If you
                            disable or refuse cookies, please note that some
                            parts of this site may then be inaccessible or not
                            function properly.{" "}
                          </li>
                        </ul>
                      </div>
                      <p className="mt-4">
                        We do not control third parties’ collection or use of
                        your information to serve interest-based advertising.
                        However these third parties may provide you with ways to
                        choose not to have your information collected or used in
                        this way. You can opt out of receiving targeted ads from
                        members of the Network Advertising Initiative (“NAI”) on
                        the NAI’s website.{" "}
                      </p>
                      <h3 className="mt-4">
                        Accessing and Correcting Your Information{" "}
                      </h3>
                      <p className="mt-4">
                        You can review and change your personal information by
                        logging into the Website and visiting your account
                        profile page.{" "}
                      </p>
                      <p className="mt-4">
                        If you delete your User Contributions from the Website,
                        copies of your User Contributions may remain viewable in
                        cached and archived pages, or might have been copied or
                        stored by other Website users. Proper access and use of
                        information provided on the Website, including User
                        Contributions, is governed by our terms of use.{" "}
                      </p>
                      <h3 className="mt-4">Data Security </h3>
                      <p className="mt-4">
                        We have implemented measures designed to secure your
                        personal information from accidental loss and from
                        unauthorized access, use, alteration, and disclosure.
                        Any payment transactions will be encrypted.{" "}
                      </p>
                      <p className="mt-4">
                        The safety and security of your information also depends
                        on you. Where we have given you (or where you have
                        chosen) a password for access to certain parts of our
                        Website, you are responsible for keeping this password
                        confidential. We ask you not to share your password with
                        anyone.{" "}
                      </p>
                      <p className="mt-4">
                        Unfortunately, the transmission of information via the
                        internet is not completely secure. Although we do our
                        best to protect your personal information, we cannot
                        guarantee the security of your personal information
                        transmitted to our Website. Any transmission of personal
                        information is at your own risk. We are not responsible
                        for circumvention of any privacy settings or security
                        measures contained on the Website.{" "}
                      </p>
                      <h3 className="mt-4">Changes to Our Privacy Policy </h3>
                      It is our policy to post any changes we make to our
                      privacy policy on this page. If we make material changes
                      to how we treat our users’ personal information, we will
                      notify you through a notice on the Website home page. The
                      date the privacy policy was last revised is identified at
                      the top of the page. You are responsible for ensuring we
                      have an up-to-date active and deliverable email address
                      for you, and for periodically visiting our Website and
                      this privacy policy to check for any changes.
                      <h3 className="mt-4">Contact Information </h3>
                      <p className="mt-4">
                        To ask questions or comment about this privacy policy
                        and our privacy practices, contact us at:{" "}
                      </p>
                      <dl>
                        <dt>8480 E. Orchard Road</dt>
                        <dt>Suite 3000</dt>
                        <dt>Greenwood Village, CO 80111</dt>
                        <dt>Support@hana-software.com</dt>
                        <dt>or via our toll-free number:</dt>
                        <dt>888-217-7508</dt>
                      </dl>
                      <p className="mt-4">
                        To register a complaint or concern, please email{" "}
                        <Link to="mailto:support@hana-software.com">
                          {" "}
                          support@hana-software.com
                        </Link>
                        .{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
