import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function TermOfService() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs col-sm-12 col-md-12 col-lg-12 p-0">
            <header className="genric-p">
              <div className="gen-ban-cont">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-xs col-sm-12 col-md-6 col-lg-6 offset-md-3 offset-lg-3 text-center">
                      <h1 className="text-white">Terms of Service</h1>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <section className="contact-us">
              <div className="container address-detail">
                <div className="row">
                  <h2>Terms of Use Version 1.0</h2>
                  <p className="mt-4">
                    The website located at www.hana-software.com (the “Site”) is
                    a copyrighted work belonging to Hana Software Inc.
                    (“Company”, “us”, “our”, and “we”). Certain features of the
                    Site may be subject to additional guidelines, terms, or
                    rules, which will be posted on the Site in connection with
                    such features. All such additional terms, guidelines, and
                    rules are incorporated by reference into these Terms.
                  </p>
                  <p className="mt-4">
                    These Terms of Use (these “Terms”) set forth the legally
                    binding terms and conditions that govern your use of the
                    Site. By accessing or using the Site, you are accepting
                    these Terms (on behalf of yourself or the entity that you
                    represent), and you represent and warrant that you have the
                    right, authority, and capacity to enter into these Terms (on
                    behalf of yourself or the entity that you represent). you
                    may not access or use the Site or accept the Terms if you
                    are not at least 18 years old. If you do not agree with all
                    of the provisions of these Terms, do not access and/or use
                    the Site.
                  </p>
                  <p></p>
                  <p className="mt-4">
                    Please be aware that section 8.2 of THESE TERMS CONTAINS
                    PROVISIONS GOVERNING HOW DISPUTES THAT YOU AND WE HAVE
                    AGAINST EACH OTHER ARE RESOLVED, INCLUDING, WITHOUT
                    LIMITATION, ANY DISPUTES THAT AROSE OR WERE ASSERTED PRIOR
                    TO THE EFFECTIVE DATE OF YOUR ACCEPTANCE OF THESE TERMS. IN
                    PARTICULAR, IT CONTAINS AN ARBITRATION AGREEMENT WHICH WILL,
                    WITH LIMITED EXCEPTIONS, REQUIRE DISPUTES BETWEEN US TO BE
                    SUBMITTED TO BINDING AND FINAL ARBITRATION.{" "}
                  </p>
                  <h4 className="mt-4">
                    {" "}
                    UNLESS YOU OPT OUT OF THE ARBITRATION AGREEMENT:{" "}
                  </h4>
                  <div className="listalign">
                    <ul>
                      <li>
                        (1) YOU WILL ONLY BE PERMITTED TO PURSUE DISPUTES OR
                        CLAIMS AND SEEK RELIEF AGAINST US ON AN INDIVIDUAL
                        BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS
                        OR REPRESENTATIVE ACTION OR PROCEEDING; AND{" "}
                      </li>
                      <li>
                        (2) YOU ARE WAIVING YOUR RIGHT TO PURSUE DISPUTES OR
                        CLAIMS AND SEEK RELIEF IN A COURT OF LAW AND TO HAVE A
                        JURY TRIAL
                      </li>
                    </ul>
                  </div>
                  <h3 className="mt-4">1. Accounts </h3>
                  <p className="mt-1">
                    1.1 Account Creation. In order to use certain features of
                    the Site, you must register for an account (“Account”) and
                    provide certain information about yourself as prompted by
                    the account registration form. You represent and warrant
                    that: (a) all required registration information you submit
                    is truthful and accurate; (b) you will maintain the accuracy
                    of such information. You may delete your Account at any
                    time, for any reason, by following the instructions on the
                    Site. Company may suspend or terminate your Account in
                    accordance with Section 7.{" "}
                  </p>
                  <p className="mt-4">
                    1.2 Account Responsibilities. You are responsible for
                    maintaining the confidentiality of your Account login
                    information and are fully responsible for all activities
                    that occur under your Account. You agree to immediately
                    notify Company of any unauthorized use, or suspected
                    unauthorized use of your Account or any other breach of
                    security. Company cannot and will not be liable for any loss
                    or damage arising from your failure to comply with the above
                    requirements.
                  </p>
                  <h3 className="mt-4">2. Access to the Site</h3>
                  <p className="mt-1">
                    2.1 License. Subject to these Terms, Company grants you a
                    non-transferable, non-exclusive, revocable, limited license
                    to use and access the Site solely for your own personal,
                    noncommercial use.
                  </p>
                  <p className="mt-4">
                    2.2 Certain Restrictions. The rights granted to you in these
                    Terms are subject to the following restrictions: (a) you
                    shall not license, sell, rent, lease, transfer, assign,
                    distribute, host, or otherwise commercially exploit the
                    Site, whether in whole or in part, or any content displayed
                    on the Site; (b) you shall not modify, make derivative works
                    of, disassemble, reverse compile or reverse engineer any
                    part of the Site; (c) you shall not access the Site in order
                    to build a similar or competitive website, product, or
                    service; and (d) except as expressly stated herein, no part
                    of the Site may be copied, reproduced, distributed,
                    republished, downloaded, displayed, posted or transmitted in
                    any form or by any means. Unless otherwise indicated, any
                    future release, update, or other addition to functionality
                    of the Site shall be subject to these Terms. All copyright
                    and other proprietary notices on the Site (or on any content
                    displayed on the Site) must be retained on all copies
                    thereof.
                  </p>
                  <p className="mt-4">
                    2.3 Modification. Company reserves the right, at any time,
                    to modify, suspend, or discontinue the Site (in whole or in
                    part) with or without notice to you. You agree that Company
                    will not be liable to you or to any third party for any
                    modification, suspension, or discontinuation of the Site or
                    any part thereof.{" "}
                  </p>
                  <p className="mt-4">
                    2.4 No Support or Maintenance. You acknowledge and agree
                    that Company will have no obligation to provide you with any
                    support or maintenance in connection with the Site.
                  </p>
                  <p className="mt-4">
                    2.5 Ownership. You acknowledge that all the intellectual
                    property rights, including copyrights, patents, trade marks,
                    and trade secrets, in the Site and its content are owned by
                    Company or Company’s suppliers. Neither these Terms (nor
                    your access to the Site) transfers to you or any third party
                    any rights, title or interest in or to such intellectual
                    property rights, except for the limited access rights
                    expressly set forth in Section 2.1. Company and its
                    suppliers reserve all rights not granted in these Terms.
                    There are no implied licenses granted under these Terms.
                  </p>
                  <p className="mt-4">
                    2.6 Feedback. If you provide Company with any feedback or
                    suggestions regarding the Site (“Feedback”), you hereby
                    assign to Company all rights in such Feedback and agree that
                    Company shall have the right to use and fully exploit such
                    Feedback and related information in any manner it deems
                    appropriate. Company will treat any Feedback you provide to
                    Company as non-confidential and non-proprietary. You agree
                    that you will not submit to Company any information or ideas
                    that you consider to be confidential or proprietary.
                  </p>
                  <h3 className="mt-4">3. Indemnification.</h3>
                  <p className="mt-4">
                    You agree to indemnify and hold Company (and its officers,
                    employees, and agents) harmless, including costs and
                    attorneys’ fees, from any claim or demand made by any third
                    party due to or arising out of (a) your use of the Site, (b)
                    your violation of these Terms or (c) your violation of
                    applicable laws or regulations. Company reserves the right,
                    at your expense, to assume the exclusive defense and control
                    of any matter for which you are required to indemnify us,
                    and you agree to cooperate with our defense of these claims.
                    You agree not to settle any matter without the prior written
                    consent of Company. Company will use reasonable efforts to
                    notify you of any such claim, action or proceeding upon
                    becoming aware of it.
                  </p>
                  <h3 className="mt-4">
                    4. Third-Party Links &amp; Ads; Other Users{" "}
                  </h3>
                  <p className="mt-4">
                    4.1 Third-Party Links &amp; Ads. The Site may contain links
                    to third-party websites and services, and/or display
                    advertisements for third parties (collectively, “Third-Party
                    Links &amp; Ads”). Such Third-Party Links &amp; Ads are not
                    under the control of Company, and Company is not responsible
                    for any Third-Party Links &amp; Ads. Company provides access
                    to these Third-Party Links &amp; Ads only as a convenience
                    to you, and does not review, approve, monitor, endorse,
                    warrant, or make any representations with respect to
                    Third-Party Links &amp; Ads. You use all Third-Party Links
                    &amp; Ads at your own risk, and should apply a suitable
                    level of caution and discretion in doing so. When you click
                    on any of the Third-Party Links &amp; Ads, the applicable
                    third party’s terms and policies apply, including the third
                    party’s privacy and data gathering practices. You should
                    make whatever investigation you feel necessary or
                    appropriate before proceeding with any transaction in
                    connection with such Third-Party Links &amp; Ads.
                  </p>
                  <p className="mt-4">
                    4.2 Other Users. Your interactions with other Site users are
                    solely between you and such users. You agree that Company
                    will not be responsible for any loss or damage incurred as
                    the result of any such interactions. If there is a dispute
                    between you and any Site user, we are under no obligation to
                    become involved.
                  </p>
                  <p className="mt-4">
                    4.3 Release. You hereby release and forever discharge the
                    Company (and our officers, employees, agents, successors,
                    and assigns) from, and hereby waive and relinquish, each and
                    every past, present and future dispute, claim, controversy,
                    demand, right, obligation, liability, action and cause of
                    action of every kind and nature (including personal
                    injuries, death, and property damage), that has arisen or
                    arises directly or indirectly out of, or that relates
                    directly or indirectly to, the Site (including any
                    interactions with, or act or omission of, other Site users
                    or any Third-Party Links &amp; Ads). IF YOU ARE A CALIFORNIA
                    RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION
                    1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: “A
                    GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR
                    OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS
                    OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF
                    KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER
                    SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.”
                  </p>
                  <h3 className="mt-4">5. Disclaimers</h3>
                  <p className="mt-4">
                    THE SITE IS PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS,
                    AND COMPANY (AND OUR SUPPLIERS) EXPRESSLY DISCLAIM ANY AND
                    ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS,
                    IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR
                    CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                    PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR
                    NON-INFRINGEMENT. WE (AND OUR SUPPLIERS) MAKE NO WARRANTY
                    THAT THE SITE WILL MEET YOUR REQUIREMENTS, WILL BE AVAILABLE
                    ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, OR
                    WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL
                    CODE, COMPLETE, LEGAL, OR SAFE. IF APPLICABLE LAW REQUIRES
                    ANY WARRANTIES WITH RESPECT TO THE SITE, ALL SUCH WARRANTIES
                    ARE LIMITED IN DURATION TO NINETY (90) DAYS FROM THE DATE OF
                    FIRST USE.
                  </p>
                  <p className="mt-4">
                    SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED
                    WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
                    SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG AN
                    IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT
                    APPLY TO YOU.
                  </p>
                  <h3 className="mt-4">6. Limitation on Liability </h3>
                  <p className="mt-4">
                    TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL
                    COMPANY (OR OUR SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD
                    PARTY FOR ANY LOST PROFITS, LOST DATA, COSTS OF PROCUREMENT
                    OF SUBSTITUTE PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL,
                    EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING
                    FROM OR RELATING TO THESE TERMS OR YOUR USE OF, OR INABILITY
                    TO USE, THE SITE, EVEN IF COMPANY HAS BEEN ADVISED OF THE
                    POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SITE
                    IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY
                    RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER
                    SYSTEM, OR LOSS OF DATA RESULTING THEREFROM.
                  </p>
                  <p className="mt-4">
                    TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING
                    ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO
                    YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THESE TERMS
                    (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE
                    ACTION), WILL AT ALL TIMES BE LIMITED TO A MAXIMUM OF FIFTY
                    US DOLLARS (U.S. $50). THE EXISTENCE OF MORE THAN ONE CLAIM
                    WILL NOT ENLARGE THIS LIMIT. YOU AGREE THAT OUR SUPPLIERS
                    WILL HAVE NO LIABILITY OF ANY KIND ARISING FROM OR RELATING
                    TO THESE TERMS.
                  </p>
                  <p className="mt-4">
                    SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION
                    OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE
                    ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
                  </p>
                  <h3 className="mt-4">7. Term and Termination.</h3>
                  <p className="mt-4">
                    Subject to this Section, these Terms will remain in full
                    force and effect while you use the Site. We may suspend or
                    terminate your rights to use the Site (including your
                    Account) at any time for any reason at our sole discretion,
                    including for any use of the Site in violation of these
                    Terms. Upon termination of your rights under these Terms,
                    your Account and right to access and use the Site will
                    terminate immediately. Company will not have any liability
                    whatsoever to you for any termination of your rights under
                    these Terms, including for termination of your Account. Even
                    after your rights under these Terms are terminated, the
                    following provisions of these Terms will remain in effect:
                    Sections 2.2 through 2.6 and Sections 3 through 8.
                  </p>
                  <h3 className="mt-4">8. General </h3>
                  <p className="mt-4">
                    8.1 Changes. These Terms are subject to occasional revision,
                    and if we make any substantial changes, we may notify you by
                    sending you an e-mail to the last e-mail address you
                    provided to us (if any), and/or by prominently posting
                    notice of the changes on our Site. You are responsible for
                    providing us with your most current e-mail address. In the
                    event that the last e-mail address that you have provided us
                    is not valid, or for any reason is not capable of delivering
                    to you the notice described above, our dispatch of the
                    e-mail containing such notice will nonetheless constitute
                    effective notice of the changes described in the notice.
                    Continued use of our Site following notice of such changes
                    shall indicate your acknowledgement of such changes and
                    agreement to be bound by the terms and conditions of such
                    changes.{" "}
                  </p>
                  <p className="mt-4">
                    8.2 Dispute Resolution. Please read this Section 8.2
                    (sometimes referred to herein as this “Arbitration
                    Agreement”) carefully. It is part of your contract with
                    Company and affects your rights. It contains procedures for
                    MANDATORY BINDING ARBITRATION AND A CLASS ACTION WAIVER.{" "}
                  </p>
                  <div className="listalign mt-4">
                    <ul>
                      <li>
                        (2.a) Applicability of Arbitration Agreement. All claims
                        and disputes (excluding claims for injunctive or other
                        equitable relief as set forth below) in connection with
                        these Terms or the use of any product or service
                        provided by the Company that cannot be resolved
                        informally or in small claims court shall be resolved by
                        binding arbitration on an individual basis under the
                        terms of this Arbitration Agreement. Unless otherwise
                        agreed to, all arbitration proceedings shall be held in
                        English. This Arbitration Agreement applies to you and
                        the Company, and to any subsidiaries, affiliates,
                        agents, employees, predecessors in interest, successors,
                        and assigns, as well as all authorized or unauthorized
                        users or beneficiaries of services or goods provided
                        under these Terms.
                      </li>
                      <li>
                        {" "}
                        (2.b) Notice Requirement and Informal Dispute
                        Resolution. Before either party may seek arbitration,
                        the party must first send to the other party a written
                        Notice of Dispute (“Notice”) describing the nature and
                        basis of the claim or dispute, and the requested relief.
                        A Notice to the Company should be sent to: 8480 E.
                        Orchard Road, Suite 3000, Greenwood Village, Colorado
                        80111. After the Notice is received, you and the Company
                        may attempt to resolve the claim or dispute informally.
                        If you and the Company do not resolve the claim or
                        dispute within thirty (30) days after the Notice is
                        received, either party may begin an arbitration
                        proceeding. The amount of any settlement offer made by
                        any party may not be disclosed to the arbitrator until
                        after the arbitrator has determined the amount of the
                        award, if any, to which either party is entitled.
                      </li>
                      <li>
                        {" "}
                        (2.c) Arbitration Rules. The Federal Arbitration Act
                        governs the interpretation and enforcement of this
                        Arbitration Agreement. The arbitration will be conducted
                        by JAMS, an established alternative dispute resolution
                        provider.&nbsp;&nbsp;Disputes involving claims,
                        counterclaims, or request for relief under $250,000, not
                        inclusive of attorneys’ fees and interest, shall be
                        subject to JAMS’s most current version of the
                        Streamlined Arbitration Rules and procedures available
                        at
                        http://www.jamsadr.com/rules-streamlined-arbitration/;
                        all other disputes shall be subject to JAMS’s most
                        current version of the Comprehensive Arbitration Rules
                        and Procedures, available at
                        http://www.jamsadr.com/rules-comprehensive-arbitration/.
                        JAMS’s rules are also available at www.jamsadr.com or by
                        calling JAMS at 800-352-5267. If JAMS is not available
                        to arbitrate, the parties will select an alternative
                        arbitral forum. Each party shall bear its own costs
                        (including attorney’s fees) and disbursements arising
                        out of the arbitration and shall pay an equal share of
                        the fees and costs of the ADR Provider. You may choose
                        to have the arbitration conducted by telephone, based on
                        written submissions, or in person in the country where
                        you live or at another mutually agreed location. Any
                        judgment on the award rendered by the arbitrator may be
                        entered in any court of competent jurisdiction.
                      </li>
                      <li>
                        {" "}
                        (2.d) Authority of Arbitrator. The arbitrator shall have
                        exclusive authority to (i) determine the scope and
                        enforceability of this Arbitration Agreement and (ii)
                        resolve any dispute related to the interpretation,
                        applicability, enforceability or formation of this
                        Arbitration Agreement including, but not limited to, any
                        assertion that all or any part of this Arbitration
                        Agreement is void or voidable. The arbitration will
                        decide the rights and liabilities, if any, of you and
                        Company. The arbitration proceeding will not be
                        consolidated with any other matters or joined with any
                        other cases or parties.&nbsp; The arbitrator shall have
                        the authority to grant motions dispositive of all or
                        part of any claim. The arbitrator shall have the
                        authority to award monetary damages and to grant any
                        non-monetary remedy or relief available to an individual
                        under applicable law, the arbitral forum’s rules, and
                        these Terms (including the Arbitration Agreement). The
                        arbitrator shall issue a written award and statement of
                        decision describing the essential findings and
                        conclusions on which the award is based, including the
                        calculation of any damages awarded.&nbsp; The arbitrator
                        has the same authority to award relief on an individual
                        basis that a judge in a court of law would have.&nbsp;
                        The award of the arbitrator is final and binding upon
                        you and us.
                      </li>
                      <li>
                        {" "}
                        (2.e) Waiver of Jury Trial. YOU AND COMPANY HEREBY WAIVE
                        ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT
                        AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. You and
                        Company are instead electing that all disputes, claims,
                        or requests for relief shall be resolved by arbitration
                        under this Arbitration Agreement, except as specified in
                        Section 8.2(a) (Applicability of Arbitration Agreement)
                        above.&nbsp; An arbitrator can award on an individual
                        basis the same damages and relief as a court and must
                        follow these Terms as a court would. However, there is
                        no judge or jury in arbitration, and court review of an
                        arbitration award is subject to very limited review.
                      </li>
                      <li>
                        {" "}
                        (2.f) Waiver of Class or Other Non-Individualized
                        Relief. ALL DISPUTES, CLAIMS, AND REQUESTS FOR RELIEF
                        WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE
                        ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS OR
                        COLLECTIVE BASIS, ONLY INDIVIDUAL RELIEF IS AVAILABLE,
                        AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE
                        ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER
                        CUSTOMER OR USER. If a decision is issued stating that
                        applicable law precludes enforcement of any of this
                        section’s limitations as to a given dispute, claim, or
                        request for relief, then such aspect must be severed
                        from the arbitration and brought into the State or
                        Federal Courts located in the State of Colorado. All
                        other disputes, claims, or requests for relief shall be
                        arbitrated.
                      </li>
                      <li>
                        {" "}
                        (2.g) 30-Day Right to Opt Out. You have the right to opt
                        out of the provisions of this Arbitration Agreement by
                        sending written notice of your decision to opt out to:
                        8480 E. Orchard Rd., Suite 3000, Greenwood Village, CO
                        80111, within thirty (30) days after first becoming
                        subject to this Arbitration Agreement. Your notice must
                        include your name and address, your Company username (if
                        any), the email address you used to set up your Company
                        account (if you have one), and an unequivocal statement
                        that you want to opt out of this Arbitration Agreement.
                        If you opt out of this Arbitration Agreement, all other
                        parts of these Terms will continue to apply to you.
                        Opting out of this Arbitration Agreement has no effect
                        on any other arbitration agreements that you may
                        currently have, or may enter in the future, with us.
                      </li>
                      <li>
                        {" "}
                        (2.h) Severability. Except as provided in Section 8.2(f)
                        (Waiver of Class or Other Non-Individualized Relief), if
                        any part or parts of this Arbitration Agreement are
                        found under the law to be invalid or unenforceable, then
                        such specific part or parts shall be of no force and
                        effect and shall be severed and the remainder of the
                        Arbitration Agreement shall continue in full force and
                        effect.
                      </li>
                      <li>
                        {" "}
                        (2.i) Survival of Agreement. This Arbitration Agreement
                        will survive the termination of your relationship with
                        Company.
                      </li>
                      <li>
                        {" "}
                        (2.j) Modification. Notwithstanding any provision in
                        these Terms to the contrary, we agree that if Company
                        makes any future material change to this Arbitration
                        Agreement, you may reject that change within thirty (30)
                        days of such change becoming effective by writing
                        Company at the following address: 8480 E. Orchard Rd.,
                        Suite 3000, Greenwood Village, CO 80111.
                      </li>
                    </ul>
                  </div>
                  <p className="mt-4">
                    8.3 Export. The Site may be subject to U.S. export control
                    laws and may be subject to export or import regulations in
                    other countries. You agree not to export, reexport, or
                    transfer, directly or indirectly, any U.S. technical data
                    acquired from Company, or any products utilizing such data,
                    in violation of the United States export laws or
                    regulations.{" "}
                  </p>
                  <p className="mt-4">
                    8.4 Disclosures. Company is located at the address in
                    Section 8.8. If you are a California resident, you may
                    report complaints to the Complaint Assistance Unit of the
                    Division of Consumer Product of the California Department of
                    Consumer Affairs by contacting them in writing at 400 R
                    Street, Sacramento, CA 95814, or by telephone at (800)
                    952-5210.
                  </p>
                  <p className="mt-4">
                    8.5 Electronic Communications. The communications between
                    you and Company use electronic means, whether you use the
                    Site or send us emails, or whether Company posts notices on
                    the Site or communicates with you via email. For contractual
                    purposes, you (a) consent to receive communications from
                    Company in an electronic form; and (b) agree that all terms
                    and conditions, agreements, notices, disclosures, and other
                    communications that Company provides to you electronically
                    satisfy any legal requirement that such communications would
                    satisfy if it were be in a hardcopy writing. The foregoing
                    does not affect your non-waivable rights.
                  </p>
                  <p className="mt-4">
                    8.6 Entire Terms. These Terms constitute the entire
                    agreement between you and us regarding the use of the Site.
                    Our failure to exercise or enforce any right or provision of
                    these Terms shall not operate as a waiver of such right or
                    provision. The section titles in these Terms are for
                    convenience only and have no legal or contractual effect.
                    The word “including” means “including without limitation”.
                    If any provision of these Terms is, for any reason, held to
                    be invalid or unenforceable, the other provisions of these
                    Terms will be unimpaired and the invalid or unenforceable
                    provision will be deemed modified so that it is valid and
                    enforceable to the maximum extent permitted by law. Your
                    relationship to Company is that of an independent
                    contractor, and neither party is an agent or partner of the
                    other. These Terms, and your rights and obligations herein,
                    may not be assigned, subcontracted, delegated, or otherwise
                    transferred by you without Company’s prior written consent,
                    and any attempted assignment, subcontract, delegation, or
                    transfer in violation of the foregoing will be null and
                    void. Company may freely assign these Terms. The terms and
                    conditions set forth in these Terms shall be binding upon
                    assignees.{" "}
                  </p>
                  <p className="mt-4">
                    8.7 Copyright/Trademark Information. Copyright © 2021 Hana
                    Software Inc. All rights reserved. All trademarks, logos and
                    service marks (“Marks”) displayed on the Site are our
                    property or the property of other third parties. You are not
                    permitted to use these Marks without our prior written
                    consent or the consent of such third party which may own the
                    Marks.
                  </p>
                  <p className="mt-4">8.8 Contact Information: </p>
                  <ul>
                    <p className="mt-4">Hana Software Support Team </p>
                    <dl>
                      Address:
                      <dt>8480 E. Orchard Road</dt>
                      <dt>Suite 3000</dt>
                      <dt>Greenwood Village, Colorado 80111</dt>
                      <dt>Telephone: 888-217-7508</dt>
                      <dt>
                        Email:
                        <Link to="mailto:support@hana-software.com">
                          {" "}
                          support@hana-software.com
                        </Link>
                      </dt>
                    </dl>
                  </ul>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermOfService;
