import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "../App.css";
import logo from "../Assets/Images/newLogo.png";
import { Alert, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { detectWebView } from "../Common/utils/universalFunctions";

const WEBBASEURL = process.env.REACT_APP_WEB_BASE_URL;
const ADMINBASEURL = process.env.REACT_APP_ADMIN_BASE_URL;

function NavbarH() {
  const [isSticky, setIsSticky] = useState(false);
  const [text, setText] = useState("");
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [activeNav, setActiveNav] = useState(1);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();
  const path = window.location.pathname;
 
  let redirectHome = async () => {
    await window?.ReactNativeWebView?.postMessage("REDIRECT_HOME");
    setShowConfirmation(false);
  };
  
  const handleLogoClick=()=>{
    const isInWebView =  detectWebView();
       if(isInWebView && path ==='/privacypolicy'){
        setShowConfirmation(true);
       }
       else if (isInWebView && path==='/termofservice'){
        setShowConfirmation(true);
       }else{
        navigate("/");
       }
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const location = useLocation();
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 10) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSignUp = () => {
    window.location.href = `${WEBBASEURL}/login`;
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleOk = () => {
    if (text === "ResureRow") {
      window.location.href = `${ADMINBASEURL}/non_disclosure_agreement`;
      handleClose();
    } else {
      handleClose();
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 1000);
    }
  };
  useEffect(() => {
    if (location.pathname === "/") {
      setActiveNav(1);
    } else {
      setActiveNav(3);
    }
  }, [location.pathname]);
  
  return (
    <div
      style={{
        backgroundColor: isSticky ? "rgb(4,49,85)" : "transparent",
      }}
    >
      <div
        style={{
          display: alert ? "flex" : "none",
          position: "absolute",
          top: 120,
          left: "37%",
          fontSize: "25px",
        }}
      >
        <Alert className="alert alert-danger" role="alert" variant="Secondary">
          Password does not match !
        </Alert>
      </div>
      <Modal show={showConfirmation}
             style={{ marginTop: '100px' }}
             onHide={() => setShowConfirmation(false)}>
        <Modal.Body style={{textAlign:"center",height:"20vh",display:"flex",justifyContent:"center"}}>
          <div>
            <p>Are you sure you want to exit the RE-Sure site?</p>
            <div style={{textAlign:"end"}}>
             <Button variant="secondary" size="sm" style={{ width: '100px', borderRadius: '0' }} onClick={handleCancel}>
              No
             </Button>
            <Button variant="primary" size="sm" style={{ width: '100px', borderRadius: '0', marginLeft:"10px" }} onClick={redirectHome}>
              Yes
            </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Navbar className="d-flex justify-content-space-evenly" expand="lg">
        <Container>
          <Navbar.Brand>
            {/* <Link to="/" style={{ textDecoration: "none", color: "inherit" }}> */}
              <img
                onClick={() => handleLogoClick()}
                src={logo}
                width="100"
                height="65"
                style={{cursor: "pointer"}}
                className="d-inline-block align-top"
                alt="Logo"
              />
            {/* </Link> */}
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav mx-auto">
            <Nav className={`mx-auto`}>
              <Nav.Link
                active={activeNav === 1 && true}
                style={{
                  color: "white",
                  height: "40px",
                  textDecoration: "none",
                }}
              >
                {" "}
                <Link
                  to="/"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {" "}
                  <span> Home </span>{" "}
                </Link>
              </Nav.Link>
              <Nav.Link
                style={{ color: "white", height: "40px" }}
                onClick={() => {
                  handleShow();
                }}
              >
                NDA
              </Nav.Link>
              <Nav.Link
                active={activeNav === 3 && true}
                style={{ color: "#fff", height: "40px" }}
              >
                <Link
                  to="/support"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {" "}
                  <span></span> SUPPORT{" "}
                </Link>
              </Nav.Link>
            </Nav>
            <Nav.Item>
              <Button
                id="signUpButton"
                onClick={handleSignUp}
                className="d-flex loginButton"
                size="md"
                style={{
                  marginLeft: 10,
                  borderRadius: 0,
                  backgroundColor: "#0077d8",
                  padding: "8px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                }}
              >
                LOGIN / SIGN UP
              </Button>
            </Nav.Item>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal style={{ zIndex: 10000 }} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4
              style={{
                color: "rgb(80, 80, 80)",
              }}
            >
              Please enter Password
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            onChange={(e) => setText(e.target.value)}
            style={{
              width: "100%",
              height: "50px",
              borderRadius: "8px",
              margin: "2rem 0 1rem 0",
              fontSize: "24px",
            }}
            placeholder="Enter password"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              padding: "0.37rem 1.3rem 0.37rem 1.3rem",
            }}
            variant="primary"
            onClick={handleOk}
          >
            Okay
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default NavbarH;
