import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Banner from "./Banner";
import ChooseRole from "./ChooseRole";
import Interface from "./Interface";
import UniqueFeature from "./UniqueFeature";
import Spotlight from "./Spotlight";
import Download from "./Download";

function HomeRoutes() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);
  return (
    <div
      style={{
        overflow: "hidden",
      }}
    >
      <div id="editor">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs col-sm-12 col-md-12 col-lg-12 p-0">
              <header className="hero">
                <Banner />
              </header>
              <UniqueFeature />
              <ChooseRole />
              <Interface />
              <Spotlight />
              <Download />
              {/* <Footer /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeRoutes;
