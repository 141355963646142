import React, { useEffect } from "react";
import combineImage from "../Assets/Images/combineImage.png";
import appleplaystorepng from "../Assets/Images/apple-play-store.png";
import googleplaystore from "../Assets/Images/google-play-store.png";
import { Link } from "react-router-dom";
const WEBBASEURL = process.env.REACT_APP_WEB_BASE_URL

function Support() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs col-sm-12 col-md-12 col-lg-12 p-0">
            <header className="genric-p">
              <div className="gen-ban-cont">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-xs col-sm-12 col-md-6 col-lg-6 offset-md-3 offset-lg-3 text-center">
                      <h1 className="text-white">Support</h1>
                      <p className="text-white">
                        Have questions about our products, features, trials, or
                        pricing? Need a demo? Our teams will help you.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <section className="contact-us">
              <div className="address-detail">
                <div className="container">
                  <div className="row">
                    {/* <div className="col-xs col-sm-12 col-md-6 col-lg-6 text-center">
                      <figure>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40.732"
                          height="36.206"
                          viewBox="0 0 40.732 36.206"
                        >
                          <path
                            id="address"
                            d="M20.366,0a8.91,8.91,0,0,0-8.91,8.91c0,3.978,5.823,11.23,8.054,13.862a1.115,1.115,0,0,0,1.711,0c2.231-2.632,8.054-9.883,8.054-13.862A8.91,8.91,0,0,0,20.366,0Zm0,11.88a2.97,2.97,0,1,1,2.97-2.97A2.97,2.97,0,0,1,20.366,11.88ZM1.423,15.271A2.263,2.263,0,0,0,0,17.372v17.7a1.132,1.132,0,0,0,1.551,1.051l9.763-4.444V15.2a21.415,21.415,0,0,1-1.5-3.283ZM20.366,25.434a3.379,3.379,0,0,1-2.582-1.2c-1.39-1.641-2.869-3.509-4.207-5.425V31.68l13.577,4.526V18.81c-1.338,1.916-2.816,3.785-4.207,5.425A3.38,3.38,0,0,1,20.366,25.434ZM39.18,11.4,29.418,15.84V36.206l9.892-3.957a2.263,2.263,0,0,0,1.423-2.1v-17.7A1.132,1.132,0,0,0,39.18,11.4Z"
                            fill="#fff"
                          />
                        </svg>
                      </figure>
                      <h6>Address</h6>
                      <p>
                        8480 East Orchard Road, Suite 3000, <br />
                        Greenwood Village, CO 80111
                      </p>
                    </div> */}
                    <div className="col-xs col-sm-12 col-md-6 col-lg-6 text-center">
                      <figure>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="42.789"
                          height="42.789"
                          viewBox="0 0 42.789 42.789"
                        >
                          <path
                            id="Contact-email"
                            d="M14.709,18.052H28.08a1.337,1.337,0,0,0,1.337-1.337V15.377A1.337,1.337,0,0,0,28.08,14.04H14.709a1.337,1.337,0,0,0-1.337,1.337v1.337A1.337,1.337,0,0,0,14.709,18.052Zm-1.337,6.686a1.337,1.337,0,0,0,1.337,1.337H28.08a1.337,1.337,0,0,0,1.337-1.337V23.4a1.337,1.337,0,0,0-1.337-1.337H14.709A1.337,1.337,0,0,0,13.372,23.4Zm8.023,10.123a6.676,6.676,0,0,1-3.916-1.269L0,20.965V38.778a4.011,4.011,0,0,0,4.011,4.011H38.778a4.011,4.011,0,0,0,4.011-4.011V20.965L25.311,33.591A6.682,6.682,0,0,1,21.395,34.861ZM41.252,13.618c-.74-.58-1.441-1.126-2.475-1.906V8.023a4.011,4.011,0,0,0-4.011-4.011H28.285l-.755-.548c-1.4-1.025-4.2-3.492-6.135-3.463-1.939-.029-4.729,2.438-6.135,3.463l-.755.548H8.023A4.011,4.011,0,0,0,4.011,8.023v3.689c-1.034.78-1.735,1.326-2.475,1.906A4.011,4.011,0,0,0,0,16.775v.89l8.023,5.8V8.023H34.766V23.46l8.023-5.8v-.89a4.011,4.011,0,0,0-1.537-3.157Z"
                            transform="translate(0 0)"
                            fill="#fff"
                          />
                        </svg>
                      </figure>
                      <h6>Email</h6>
                      <p>support@resure.realestate</p>
                    </div>
                    <div className="col-xs col-sm-12 col-md-6 col-lg-6 text-center">
                      <figure>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="35.871"
                          height="35.871"
                          viewBox="0 0 35.871 35.871"
                        >
                          <path
                            id="phone-in-talk"
                            d="M38.378,29.41a22.639,22.639,0,0,1-7.114-1.136,2.034,2.034,0,0,0-2.033.478l-4.384,4.384A29.983,29.983,0,0,1,11.714,20L16.1,15.6a1.913,1.913,0,0,0,.5-1.993,22.639,22.639,0,0,1-1.136-7.114A2,2,0,0,0,13.468,4.5H6.493A2,2,0,0,0,4.5,6.493,33.875,33.875,0,0,0,38.378,40.371a2,2,0,0,0,1.993-1.993V31.4A2,2,0,0,0,38.378,29.41Zm-1.993-6.975h3.986A17.937,17.937,0,0,0,22.436,4.5V8.486A13.94,13.94,0,0,1,36.385,22.436Zm-7.971,0H32.4a9.968,9.968,0,0,0-9.964-9.964v3.986A5.971,5.971,0,0,1,28.414,22.436Z"
                            transform="translate(-4.5 -4.5)"
                            fill="#fff"
                          />
                        </svg>
                      </figure>
                      <h6>Phone</h6>
                      <p>(888) 217 - 7508</p>
                    </div>
                    <div className="col-xs col-sm-12 col-md-6 col-lg-6 text-center mt-5" style={{margin:"auto"}}>
                      <Link to={`${WEBBASEURL}/bugsReport`}
                        className="support"
                      >
                        <figure>
                          <svg
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 324.274 324.274"
                            style={{
                              enableBackground: "new 0 0 324.274 324.274",
                            }}
                            xmlSpace="preserve"
                          >
                            <g>
                              <path
                                d="M34.419,298V22h138.696h0.841v33.411c0,8.301,6.753,15.055,15.053,15.055h33.154v88.5c2.443-0.484,4.957-0.75,7.528-0.75
												 c5.087,0,9.962,0.994,14.472,2.804V64.006c0-1.326-0.526-2.598-1.464-3.536L183.694,1.464C182.755,0.527,181.484,0,180.158,0
												 H27.472c-8.3,0-15.053,6.753-15.053,15.054v289.893c0,8.301,6.753,15.054,15.053,15.054h111.884
												 c-1.256-6.713,1.504-13.831,7.559-17.83c2.341-1.546,4.692-2.919,7.034-4.17H34.419z"
                              />
                              <path
                                d="M308.487,310.515c-12.254-8.092-25.057-11.423-33.599-12.795c6.02-9.685,9.564-21.448,9.564-34.129
												 c0-9.12-1.824-17.889-5.174-25.781c8.22-1.738,18.908-5.176,29.209-11.98c3.457-2.283,4.408-6.935,2.126-10.392
												 c-2.283-3.456-6.936-4.407-10.392-2.125c-10.742,7.094-22.229,9.723-29.102,10.698c-3.459-4.387-7.5-8.249-12.077-11.394
												 c0.859-3.081,1.294-6.265,1.294-9.509c0-17.861-13.062-32.393-29.117-32.393c-16.055,0-29.115,14.531-29.115,32.393
												 c0,3.244,0.435,6.428,1.294,9.509c-4.577,3.145-8.618,7.007-12.077,11.394c-6.873-0.975-18.358-3.603-29.102-10.698
												 c-3.456-2.282-8.108-1.331-10.392,2.125c-2.282,3.456-1.331,8.109,2.126,10.392c10.301,6.803,20.988,10.241,29.208,11.979
												 c-3.351,7.893-5.175,16.661-5.175,25.781c0,12.681,3.544,24.444,9.563,34.129c-8.541,1.372-21.343,4.703-33.597,12.794
												 c-3.456,2.283-4.408,6.935-2.126,10.392c1.442,2.184,3.83,3.368,6.266,3.368c1.419,0,2.854-0.402,4.126-1.242
												 c16.62-10.975,35.036-11.269,35.362-11.272c0.639-0.002,1.255-0.093,1.847-0.245c8.877,7.447,19.884,11.861,31.791,11.861
												 c11.907,0,22.914-4.415,31.791-11.861c0.598,0.153,1.22,0.244,1.865,0.245c0.183,0,18.499,0.148,35.346,11.272
												 c1.272,0.84,2.707,1.242,4.126,1.242c2.434,0,4.823-1.184,6.266-3.368C312.895,317.45,311.943,312.797,308.487,310.515z
													M238.719,296.005c0,4.142-3.357,7.5-7.5,7.5c-4.142,0-7.5-3.358-7.5-7.5v-64.83c0-4.142,3.358-7.5,7.5-7.5
												 c4.143,0,7.5,3.358,7.5,7.5V296.005z"
                              />
                              <path
                                d="M143.627,49.624h-78c-4.418,0-8,3.582-8,8c0,4.418,3.582,8,8,8h78c4.418,0,8-3.582,8-8
												 C151.627,53.206,148.045,49.624,143.627,49.624z"
                              />
                              <path
                                d="M143.627,99.624h-78c-4.418,0-8,3.582-8,8c0,4.419,3.582,8,8,8h78c4.418,0,8-3.581,8-8
												 C151.627,103.206,148.045,99.624,143.627,99.624z"
                              />
                              <path
                                d="M143.627,149.624h-78c-4.418,0-8,3.582-8,8c0,4.419,3.582,8,8,8h78c4.418,0,8-3.581,8-8
												 C151.627,153.206,148.045,149.624,143.627,149.624z"
                              />
                            </g>
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                          </svg>
                        </figure>
                        <h6>Bug Report</h6>
                        <p>
                          Minimize bugs with basic bug reporting and feedback
                          features
                        </p>
                      </Link>
                    </div>
                  </div>
                  
                </div>
              </div>
            </section>
            <section className="download">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-xs col-sm-12 col-md-6 col-lg-6 mb-2">
                    <figure>
                      <img
                        src={combineImage}
                        className="img-fluid w-100 m-auto"
                      />
                    </figure>
                  </div>
                  <div className="col-xs col-sm-12 col-md-6 col-lg-5 offset-lg-1 mb-2">
                    <h2 className="heading">
                      Manage Everything From The Palm Of Your Hands.
                    </h2>
                    <p>
                      The RE-Sure mobile app and web-based app is designed to be
                      full spectrum to begin, maintain and complete a real
                      estate transaction with compliance to all legal
                      requirements. we promise to EMPOWER. PROTECT. COMPETE.
                    </p>
                    <ul className="list-inline download-app mt-4">
                      <li className="list-inline-item">
                        <Link to="/" />
                      </li>
                      <li className="list-inline-item">
                      <Link to="/" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Support;
