import React from "react";
import combineImage from "../../Assets/Images/combineImage.png";
import { Link } from "react-router-dom";

function Download() {
  const goToTop = ()=> {
    window.scrollTo({
      top: 0,
      behavior: "auto"
    });
  }
  return (
    <div>
      <>
        <section className="download">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xs col-sm-12 col-md-6 col-lg-6 mb-2">
                <figure>
                  <img src={combineImage} className="img-fluid w-100 m-auto" />
                </figure>
              </div>
              <div className="col-xs col-sm-12 col-md-6 col-lg-5 offset-lg-1 mb-2">
                <h2 className="heading">
                  Manage Everything From The Palm Of Your Hands.
                </h2>
                <p>
                  The RE-Sure mobile app and web-based app is designed to be
                  full spectrum to begin, maintain and complete a real estate
                  transaction with compliance to all legal requirements. we
                  promise to EMPOWER. PROTECT. COMPETE.
                </p>
                <ul className="list-inline download-app mt-4">
                  <li onClick={()=>goToTop()} className="list-inline-item">
                    <Link to="/" />
                  </li>
                  <li onClick={()=>goToTop()} className="list-inline-item">
                    <Link to="/" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </>
    </div>
  );
}

export default Download;
