import React, { useEffect } from "react";
import bannerImage1 from "../../Assets/Images/webAppSlider.png";
import bannerImage2 from "../../Assets/Images/fbrs-web-app-slider-1.png";
import bannerImage3 from "../../Assets/Images/mobileappslider02.png";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";

function Banner(setScroll) {

  const goToTop = ()=> {
    window.scrollTo({
      top: 0,
      behavior: "auto"
    });
  }

  return (
    <div
      style={{
        paddingTop: "220px",
        zIndex: "0 !imporatnt",
      }}
      className="banner"
    >
      <div className="main-banner" id="banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xs col-sm-12 col-md-6 col-lg-6">
              <h1 className="text-white">
                The Complete <span>Real estate business management</span>{" "}
                Platform
              </h1>
              <h4 className="text-white">
                We Empower Real Estate Professionals With SMART Technology
              </h4>

              <ul
                style={{ zIndex: 0, important: true }}
                className="list-inline download-app mt-4"
              >
                <li
                  className="list-inline-item"
                  style={{
                    marginLeft: 0,
                  }}
                >
                  <Link to="/" onClick={()=>goToTop()}></Link>

                </li>

                <li style={{ marginLeft: "5px" }} className="list-inline-item">
                  <Link to='/' onClick={()=>goToTop()}></Link>
                </li>
              </ul>
            </div>

            <div
              style={{ zIndex: "0 !important", important: true }}
              className="col-xs col-sm-12 col-md-6 col-lg-6  mb-4 mobile-app-sec"
            >
              <div
                id="carouselExampleIndicators1"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div
                  id="carouselExampleIndicators"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <Carousel arousel-control-width controls={false}>
                    <Carousel.Item interval={1000}>
                      <img
                        src={bannerImage1}
                        alt="First slide"
                        className="d-block w-100 m-auto"
                        style={{ zIndex: "0 !important" }}
                      />
                    </Carousel.Item>

                    <Carousel.Item interval={500}>
                      <img
                        className="d-block w-75 m-auto"
                        src={bannerImage2}
                        alt="Second slide"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block"
                        style={{
                          width: "27%",
                          margin: "auto",
                        }}
                        src={bannerImage3}
                        alt="Phone Image"
                      />
                    </Carousel.Item>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
