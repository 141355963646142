import React from "react";
import spotlightvideo from "../../Assets/Images/spotlight-video.png";

function Spotlight() {
  return (
    <div style={{ zIndex: 0, important: true }}>
      <section className="spotlight" style={{ zIndex: 0, important: true }}>
        <div className="container">
          <div className="row">
            <div className="col-xs col-sm-12 col-md-8 offset-md-2 col-lg-8 offset-lg-2 text-center mb-4">
              <h1 className="large-heading text-white">
                {" "}
                RBM (Real Estate Business Management Software){" "}
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-xs col-sm-12 col-md-8 offset-md-2 col-lg-8 offset-lg-2 text-center mb-4">
              <figure>
                <img src={spotlightvideo} className="img-fluid" />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Spotlight;
