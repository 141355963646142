import React from "react";
import webAppSlider from "../../Assets/Images/webAppSlider.png";
import mobileappslider02 from "../../Assets/Images/mobileappslider02.png";
import client02 from "../../Assets/Images/client02.png";
import webappslider10 from "../../Assets/Images/webappslider10.png";
import Carousel from "react-bootstrap/Carousel";

function Interface() {
  return (
    <div>
      <section className="interface">
        <div className="container">
          <div className="row">
            <div className="col-xs col-sm-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 text-center mb-4">
              <h2 className="heading">Powerful User Interface</h2>
              <p>
                Our goal is to enhance the user experience making transactions
                more convenient, faster and secure. All the tools in the palm of
                your hand Both the mobile app and the web application are fully
                functional for contract integration and production. They both
                boast an easy to read dashboard that is user friendly and easy
                to navigate.
              </p>
            </div>
          </div>
          <div className="row py-4">
            <div className="col-xs col-sm-6 col-md-6 col-lg-6 mb-4">
              <h3>Features</h3>
              <ul className="list-style">
                <li>Contract Validation</li>
                <li>Every contract has a transaction validate method.</li>
                <li>Autofill fields and dates</li>
                <li>Form field constraint enforcement</li>
                <li>Form tracked for related/integrated contracts</li>
                <li>Changes timestamped</li>
                <li>Changes signed</li>
              </ul>
            </div>

            {/* Caurosol */}
            <div className="col-xs col-sm-6 col-md-6 col-lg-4 offset-lg-2 mb-4 mobile-app-sec">
              <div
                id="carouselExampleIndicators1"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div
                  id="carouselExampleIndicators"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <Carousel arousel-control-width controls={false}>
                    <Carousel.Item interval={1000}>
                      <img
                        src={webAppSlider}
                        alt="First slide"
                        className="d-block w-100 m-auto"
                      />
                    </Carousel.Item>

                    <Carousel.Item interval={500}>
                      <img
                        className="d-block"
                        style={{
                          width: "35%",
                          margin: "auto",
                        }}
                        src={mobileappslider02}
                        alt="Second slide"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block"
                        style={{
                          width: "35%",
                          margin: "auto",
                        }}
                        src={client02}
                        alt="Phone Image"
                      />
                    </Carousel.Item>

                    <Carousel.Item>
                      <img
                        // className="d-block"
                        // style={{
                        //   width: "30%",
                        //   margin: "auto",
                        // }}
                        src={webappslider10}
                        alt="Phone Image"
                        className="d-block w-100 m-auto"
                      />
                    </Carousel.Item>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Interface;
